import React, { type FunctionComponent, useState, useMemo, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { Request as apiRequest } from '../../helper';
import { type OutbankFeatureData } from '../../interface/Issue';
import { Icon, Link, useDisclosure } from '@chakra-ui/react';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { type Maybe } from '../../generated/types';
import { Details } from './Details';

export const OutbankFeatureList: FunctionComponent = () => {
    const [issues, setIssues] = useState<OutbankFeatureData[]>();
    const [selectedItemId, setSelectedItemId] = useState<Maybe<number> | undefined>();
    const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();

    const selectedItem = issues?.find((item) => item?.iid === selectedItemId);
    const openDetails = (selectedFeature: OutbankFeatureData): void => {
        setSelectedItemId(selectedFeature.iid);
        onOpenDetails();
    };

    useEffect(() => {
        setIssues(undefined);
        async function getIssues(): Promise<void> {
            const response = await apiRequest.get<OutbankFeatureData[]>({ path: '/gitlab/issue/outbankfeature' });
            setIssues(response.data);
        }
        getIssues();
    }, []);

    const tableData = useMemo(() => issues, [issues]);
    const columns: Array<Column<OutbankFeatureData>> = useMemo(
        () => [
            {
                Header: 'state',
                accessor: 'state',
            },
            {
                Header: 'order',
                accessor: 'order',
            },
            {
                Header: 'description',
                accessor: 'description',
            },
            {
                Header: 'notes',
                accessor: (row) => row.notes.join(', '),
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 400,
            },
            {
                Header: 'Biz',
                accessor: (row) => (row.isBusiness ? 'Business' : ''),
                Cell: (cell: any) => {
                    return cell.row.original.isBusiness ? <Icon as={IoCheckmarkCircleOutline} w={6} h={6} /> : <></>;
                },
                maxWidth: 50,
            },
            {
                Header: 'Module',
                accessor: 'module',
            },
            {
                Header: 'Platform',
                accessor: (row) => row.platform.join(', '),
            },
            {
                Header: 'Requests',
                accessor: (row) => row.countRequests,
                textAlign: 'right',
            },
            {
                Header: 'Issue',
                accessor: (row) => row.iid,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: (cell: any) => {
                    return (
                        <Link
                            href={`https://gitlab.outbankapp.com/outbank/outbank-features/-/issues/${cell.row.original.iid}`}
                            isExternal
                        >
                            {`#${cell.row.original.iid}`}
                        </Link>
                    );
                },
                textAlign: 'right',
            },
        ],
        [issues],
    );

    const allModulTypes: string[] = useMemo(() => {
        return (
            Array.from(new Set(issues?.map((item) => item.module).filter((n) => n))).sort(Intl.Collator().compare) ?? []
        );
    }, [issues]);

    return (
        <ContentContainer headline="User Requests" description="List of all User Requests issues" showSpinner={!issues}>
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        placeholder: 'Search for User Requests',
                        selectConfig: {
                            columAccessor: 'module',
                            selectOptions: <ModulType allModulTypes={allModulTypes} />,
                        },
                    }}
                    hiddenColumns={['state', 'order', 'description', 'notes']}
                    showColoredState={true}
                    coloredStateMethod={colorForState}
                    initialState={{
                        sortBy: [{ id: 'order', desc: true }],
                    }}
                    defaultPageSize={20}
                    rowClick={openDetails}
                />
            )}
            {selectedItem && selectedItem && (
                <Details isOpen={isOpenDetails} onClose={onCloseDetails} featureDetails={selectedItem} />
            )}
        </ContentContainer>
    );
};

enum StateType {
    Implementation = 3,
    Preparation = 2,
    Open = 1,
}

const colorForState = (state: StateType): string => {
    switch (state) {
        case StateType.Implementation:
            return 'green.500';
        case StateType.Preparation:
            return 'yellow.500';
        case StateType.Open:
            return 'blue.500';
    }
};

interface ModulTypeProps {
    allModulTypes: string[];
}

const ModulType: FunctionComponent<ModulTypeProps> = (props: ModulTypeProps) => {
    return (
        <>
            <option value="">All</option>
            {props.allModulTypes.map((item: string) => (
                <option value={item} key={item}>
                    {item}
                </option>
            ))}
        </>
    );
};
