import React, { type FunctionComponent, type ReactNode } from 'react';
import { Box, type BoxProps, Text } from '@chakra-ui/react';

interface Props {
    label: string;
    value?: string | number;
    children?: ReactNode;
    boxProps?: BoxProps;
    pb?: number;
}

export const DetailsElement: FunctionComponent<Props> = (props: Props) => {
    return (
        <Box pb={'pb' in props ? props.pb : 4} {...props.boxProps}>
            <Text fontSize="sm" fontWeight="bold">
                {props.label.toUpperCase()}
            </Text>
            {props.value && <Text>{props.value}</Text>}
            {props.children}
        </Box>
    );
};
