import React, { type FunctionComponent } from 'react';
import { Route, Routes as MyRoutes, Navigate } from 'react-router-dom';

import { HarList } from '../../screen/har/List';
import { List as CrashGroupList } from '../../screen/crashgroup/List';
import { List as CrashGroup } from '../../screen/crashgroup/CrashGroup';
import { DashboardWebconnect } from '../../screen/dashboards/Webconnect';
import { useKeycloak } from '@react-keycloak/web';
import { getUser } from '../../keycloak';
import { CrashList } from '../../screen/crash/List';
import { List as CrashesList } from '../../screen/crash/Last';
import { List as FeatureRequest } from '../../screen/crash/FeatureRequest';
import { List as ImporterError } from '../../screen/crash/ImporterError';
import { ProtocolList } from '../../screen/protocol/List';
import { UserList } from '../../screen/user/List';
import { NewIntegrationList } from '../../screen/newintegration/List';
import { FigmaPreview } from '../../screen/tools/FigmaPreview';
import { Overview as AppsOverview } from '../../screen/apps/Overview';
import { List as IntegrationsList } from '../../screen/integrations/List';
import { List as ExtendedList } from '../../screen/integrations/ExtendedList';
import { List as NightlyList } from '../../screen/nightly/List';
import { GroundControl } from '../../screen/groundcontrol/GroundControl';
import { MailConverter } from '../../screen/tools/MailConverter';
import { SampleData } from '../../screen/tools/SampleData';
import { OutbankFeatureList } from '../../screen/outbankfeature/List';
import { AppleAds } from '../../screen/marketing/AppleAds';
import { Dashboard } from '../../screen/dashboards/Dashboard';
import { LocalizationsDashboardList } from '../../screen/localizations/List';
import { LocalizationsDetailsList } from '../../screen/localizations/DetailsList';

export const Routes: FunctionComponent = () => {
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);
    return (
        <MyRoutes>
            <Route path="/" element={<Dashboard />} />
            {keycloak.authenticated && user && (
                <>
                    {/** Webconnect */}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/webconnect" element={<DashboardWebconnect />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/har" element={<HarList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/har/:id" element={<HarList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/crashgroups" element={<CrashGroupList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/crashgroups/:id" element={<CrashGroupList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/crashgroup/:id" element={<CrashGroup />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/crashlist/:id" element={<CrashList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/crashlist/crashgroups/:id" element={<CrashList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/crashlist/user/:id" element={<CrashList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/protocols" element={<ProtocolList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/protocols/:protocolId" element={<ProtocolList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/users" element={<UserList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/users/:id" element={<UserList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/newintegrations" element={<NewIntegrationList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/integrations" element={<IntegrationsList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/integrationsExtended" element={<ExtendedList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/lastcrashes/:quantity" element={<CrashesList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/featurerequests" element={<FeatureRequest />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/importererror" element={<ImporterError />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/localizations" element={<LocalizationsDashboardList />} />
                    )}
                    {(user.role.admin || user.role.webconnect || user.role.helpdesk) && (
                        <Route path="/localizations/:projectId" element={<LocalizationsDetailsList />} />
                    )}

                    {/** Helpdesk */}
                    {(user.role.admin || user.role.helpdesk) && <Route path="/nightly/:id" element={<NightlyList />} />}
                    {(user.role.admin || user.role.helpdesk) && (
                        <Route path="/nightly/:id/:version/:build/:file" element={<NightlyList />} />
                    )}

                    {/** App */}
                    {(user.role.admin || user.role.app) && <Route path="/apps" element={<AppsOverview />} />}

                    {/** Marketing */}
                    {(user.role.admin || user.role.marketing) && (
                        <Route path="/marketing/apple-ads" element={<AppleAds />} />
                    )}

                    {/** GroundControl */}
                    {(user.role.admin || user.role.groundcontrol) && (
                        <Route path="/groundcontrol" element={<GroundControl />} />
                    )}

                    {/** All */}
                    <Route path="/mailconverter" element={<MailConverter />} />
                    <Route path="/sampledata" element={<SampleData />} />
                    <Route path="/figmapreview" element={<FigmaPreview />} />
                    <Route path="/outbankfeature" element={<OutbankFeatureList />} />

                    <Route path="*" element={<Navigate to="/" />} />
                </>
            )}
        </MyRoutes>
    );
};
