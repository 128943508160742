import React, { type FunctionComponent, type ChangeEvent, useState, useEffect } from 'react';
import {
    type AppData,
    type AppStoreVersion,
    type AvailableBuilds,
    type BetaGroup,
    type Build,
    type Releasenotes,
} from '../../interface/AppData';
import { Checkbox, CheckboxGroup, Select, Text, VStack, useBoolean, useToast } from '@chakra-ui/react';
import { labelForPlatform, storeNameForPlatform } from './Helper';
import { InputArea } from './InputArea';
import { InputForm } from './InputForm';
import { Request as apiRequest, getErrorMessage } from '../../helper';
import LoadingIndicator from '../../components/LoadingIndicator';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    appData: AppData;
    version: AppStoreVersion;
    type: 'BETA' | 'BETA-SPARKLE' | 'STORE';
    onSubmit: (appData: AppData) => void;
}

export const SubmitVersionForm: FunctionComponent<Props> = (props) => {
    const { isOpen, onClose, appData, version, type, onSubmit } = props;
    const versionName = `${appData.name} ${labelForPlatform(version.platform)} ${version.versionString}`;

    const [isSaving, setSaving] = useBoolean();
    const [isReloading, setReloading] = useBoolean();
    const toast = useToast({ position: 'top' });

    const [builds, setBuilds] = useState<Build[]>();
    const [betaGroups, setBetaGroups] = useState<BetaGroup[]>();
    const [releasenotes, setReleasenotes] = useState<Releasenotes>();
    const [maxLength, setMaxLength] = useState<number>(4000);
    const [isBuildRequired, setBuildRequired] = useBoolean();

    const [selectedBuild, setSelectedBuild] = useState<string>('');
    const [checkedGroups, setCheckedGroups] = useState<string[]>([]);

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>): any => {
        const element = e.target;
        setReleasenotes((releasenotes: Releasenotes | undefined): Releasenotes | undefined => {
            if (releasenotes) {
                const newReleasenotes = { ...releasenotes };
                for (const entry of Object.entries(newReleasenotes)) {
                    if (entry[0] === element.name && entry[1]) {
                        entry[1].text = element.value;
                    }
                }
                return newReleasenotes;
            }
            return releasenotes;
        });
    };

    const getAvailableBuilds = async (reload: boolean): Promise<void> => {
        try {
            const response = await apiRequest.get<AvailableBuilds>({
                path: `/apps/${appData.id}/builds/${version.id}?type=${type}`,
            });
            setBuilds(response.data.builds);
            setBetaGroups(response.data.betaGroups);
            setReleasenotes(response.data.releasenotes.texts);
            setMaxLength(response.data.releasenotes.maxLength);
            response.data.isBuildRequired ? setBuildRequired.on() : setBuildRequired.off();
            if (response.data.builds && response.data.builds.length > 0) {
                setSelectedBuild(response.data.builds[0].id);
            }
        } catch (e) {
            if (reload) {
                toast({
                    title: 'Failure!',
                    description: `Could not load builds for ${versionName}: ${getErrorMessage(e)}.`,
                    status: 'error',
                    isClosable: true,
                });
            }
        }
    };

    useEffect(() => {
        setSaving.off();
        setBuilds(undefined);
        getAvailableBuilds(false);
    }, []);

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const isBuildSelected = builds?.find((b) => b.id === selectedBuild) !== undefined;
    let isSubmitDisabled = isBuildRequired && !isBuildSelected;
    if (betaGroups && !isSubmitDisabled) {
        isSubmitDisabled = checkedGroups.length === 0;
    }
    if (releasenotes && !isSubmitDisabled) {
        const textLength = Object.entries(releasenotes)
            .filter((e: [string, any]) => e[1] !== undefined)
            .map((e: [string, any]) => e[1].text.length);

        const isTooLong = textLength.some((length: number) => length > maxLength);
        const isNotSet = textLength.some((length: number) => length === 0);
        isSubmitDisabled = isTooLong || isNotSet;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    const onReload = async (): Promise<void> => {
        setReloading.on();
        await getAvailableBuilds(true);
        setReloading.off();
    };

    const doSubmit = async (): Promise<void> => {
        try {
            setSaving.on();

            const build = builds?.find((b) => b.id === selectedBuild);
            const body = {
                type,
                build,
                version: {
                    id: version.id,
                    platform: version.platform,
                },
                betaGroups: checkedGroups,
                releasenotes,
            };

            const response = await apiRequest.post<AppData>({
                path: `/apps/${appData.id}/submitVersion`,
                data: body,
            });
            onSubmit(response.data);

            onClose();
            toast({
                title: 'Success!',
                description: `Submitted ${versionName} ${props.type === 'BETA' ? 'as beta' : 'to review'}.`,
                status: 'success',
                isClosable: true,
            });
        } catch (e) {
            toast({
                title: 'Failure!',
                description: `Could not submit ${versionName} ${
                    props.type === 'BETA' ? 'as beta' : 'to review'
                }: ${getErrorMessage(e)}.`,
                status: 'error',
                isClosable: true,
            });
        }
        setSaving.off();
    };

    const inputContainer = (title: string, value: string): JSX.Element => (
        <InputArea
            key={title}
            title={title.charAt(0).toUpperCase() + title.slice(1) + ' release notes'}
            name={title}
            value={value}
            maxLength={maxLength}
            isDisabled={isSaving || isReloading}
            onChange={handleInputChange}
            height="150"
        />
    );

    const loadedContent = (
        builds: Build[],
        betaGroups: BetaGroup[] | undefined,
        releasenotes: Releasenotes,
    ): JSX.Element => (
        <VStack align="justify" spacing="8">
            {isBuildRequired && (
                <VStack align="justify" spacing="0">
                    <Text as="b">Build</Text>
                    <Select
                        isDisabled={isSaving || isReloading}
                        value={selectedBuild}
                        onChange={(element) => {
                            setSelectedBuild(element.target.value);
                        }}
                    >
                        {builds.map((b) => {
                            return (
                                <option key={b.id} value={b.id}>
                                    {[b.version, b.buildNumber].filter((s) => s !== '').join(' ')}
                                </option>
                            );
                        })}
                    </Select>
                </VStack>
            )}
            {betaGroups && (
                <VStack align="justify" spacing="0">
                    <Text as="b">Beta Group</Text>
                    <CheckboxGroup
                        isDisabled={isSaving || isReloading}
                        value={checkedGroups}
                        onChange={(value) => {
                            setCheckedGroups(value as string[]);
                        }}
                    >
                        {betaGroups.map((b) => {
                            return (
                                <Checkbox key={b.id} value={b.id}>
                                    {b.name}
                                </Checkbox>
                            );
                        })}
                    </CheckboxGroup>
                </VStack>
            )}
            <VStack align="justify" spacing="4">
                {Object.entries(releasenotes).map((entry) => {
                    return inputContainer(entry[0], entry[1].text);
                })}
            </VStack>
        </VStack>
    );

    let content;
    if (builds && releasenotes) content = loadedContent(builds, betaGroups, releasenotes);
    else content = <LoadingIndicator />;

    let subtitle;
    switch (type) {
        case 'BETA':
            subtitle = 'Release beta version';
            break;
        case 'BETA-SPARKLE':
            subtitle = 'Release beta version using Sparkle';
            break;
        case 'STORE':
            subtitle = `Submit to ${storeNameForPlatform(appData.platform)} review`;
            break;
    }

    return (
        <InputForm
            title={versionName}
            subtitle={subtitle}
            isOpen={isOpen}
            onClose={onClose}
            onReload={onReload}
            saveTitle="Submit"
            isSaveDisabled={isSubmitDisabled}
            onSave={doSubmit}
        >
            {content}
        </InputForm>
    );
};
